import { ISO8601Date, MixedContent, Locale } from '../common'
import { ReportedQuestionType } from '../quiz'
import { GrammarGameIdent } from './grammarGames'

/**
 * Grammar Game Session
 */

// Master body of a Grammar Game Session
export interface GrammarGameSessionInfo {
  id: number
  node_id: number // curriculum node selected to play
  user_id: number // player id
  user_name: string // player name
  created: ISO8601Date
  game_ident: GrammarGameIdent // game played
  responses: GrammarGameResponseInfo[] // Array of responses
  earnings: number // earnings gained from this session
}

// Body of a request to generate and return a suite of questions
export interface GrammarGameQuestionRequest {
  node_id: GrammarGameSessionInfo['node_id']
  game_ident: GrammarGameSessionInfo['game_ident']
  count: number // the number of questions required
}

// Body of a request to update a grammar game session - intended for when a game ends
export interface GrammarGameSessionCreate {
  game_ident: GrammarGameSessionInfo['game_ident']
  node_id: GrammarGameSessionInfo['node_id']
  responses: GrammarGameSessionInfo['responses']
  earnings: GrammarGameSessionInfo['earnings']
}

// Filter for fetching grammar game sessions
export interface GrammarGameSessionFilters {
  user_id: number
  game_ident?: GrammarGameIdent
  node_id?: number
}

export interface GrammarGameResponseInfoWithSessionID extends GrammarGameResponseInfo {
  session_id: number
}

export interface GrammarGameResponsesForGameIdent {
  game_ident: GrammarGameIdent
  responses: GrammarGameResponseInfoWithSessionID[]
}

export interface GrammarGameResponsesForUser {
  user_id: number
  games: GrammarGameResponsesForGameIdent[]
}

export interface GrammarGameResponsesForGroup {
  group_id: number
  users: GrammarGameResponsesForUser[]
}

/**
 * Grammar Responses
 *
 * Universal response object for every game
 *
 * Responses are saved in the session object
 *
 * Responses represent any response made within a grammar session. As such they won't always map 1:1 with the questions array
 */

export interface GrammarGameResponseInfo {
  created: ISO8601Date
  question: GrammarGameQuestionInfo
  score: number // score granted for given response
  correct: boolean // whether the response is considered correct
  params: GrammarGameResponseParams // Exclusive response data for the given game
}

export type GrammarGameResponseParams = GrammarDogGameResponseParams | GrammarMazeGameResponseParams | GrammarHippoGameResponseParams | GrammarFishGameResponseParams | GrammarBracketGameResponseParams | GrammarPirateGameResponseParams | GrammarPairGameResponseParams | GrammarDreamGameResponseParams

/* Corresponds to the TS question type - used in the game definitions to know what question types to look for */
export type GrammarGameType = 'multi' | 'bracket' | 'punctuation' | 'match' | 'sentence'

/**
 * Container class for all grammar questions
 *
 * Questions are created as individual instances, there are no "question sets" in the grammar games. They are associated with curriculum nodes and
 * the system should select questions associated with a selected node when generating a session
 */
export interface GrammarGameQuestionInfo {
  id: number
  version_id: number // Questions require previous versions to be saved so retroactive reporting isn't corrupted by updates
  node_id: number // The curriculum node this question is attached to
  created: ISO8601Date
  updated: ISO8601Date
  game_ident: GrammarGameIdent // ident of the GrammarGame this question is locked to
  params: GrammarGameQuestionParams // Exclusive properties to the question's type
}

export interface GrammarGameQuestionCreateRequest {
  node_id: GrammarGameQuestionInfo['node_id'],
  game_ident: GrammarGameQuestionInfo['game_ident'],
  params: GrammarGameQuestionInfo['params']
}
export interface GrammarGameQuestionEditRequest {
  id: GrammarGameQuestionInfo['id']
  version_id: GrammarGameQuestionInfo['version_id']
  node_id: GrammarGameQuestionInfo['node_id'],
  params: GrammarGameQuestionInfo['params']
}

// Determine the type based on the questions game type
export type GrammarGameQuestionParams = GrammarMultipleChoiceQuestionParams | GrammarBracketQuestionParams | GrammarPunctuationQuestionParams | GrammarMatchQuestionParams | GrammarSentenceQuestionParams

/// /////////////////////
// Multiple Choice Question Type
// Provide a question and multiple options
// Can be used for any game that presents a question and possible outcomes representing the answers
/// /////////////////////
export interface GrammarMultipleChoiceQuestionParams {
  type: 'multi'
  title: MixedContent // Main question text
  description?: MixedContent // Prompt or hint text
  answers: GrammarMultipleChoiceAnswer[] // Array of up to four answers
  shuffleAnswers: boolean
}

// Potential answers to a multiple choice question
export interface GrammarMultipleChoiceAnswer {
  index: number // Doesn't need to be unique globally, only within the answers array
  text: string
  correct: boolean // is this the correct answer?
}

interface GrammarMultipleChoiceResponseParams {
  answer: GrammarMultipleChoiceAnswer
}

export interface GrammarDogGameResponseParams extends GrammarMultipleChoiceResponseParams {
  bones_wagered: number // Number of bones wagered on this response
}

export interface GrammarMazeGameResponseParams extends GrammarMultipleChoiceResponseParams {
  lives_remaining: number // Lives remaining after this response
  difficulty: number // Difficulty index (will be the same throughout the responses)
}
export interface GrammarFishGameResponseParams extends GrammarMultipleChoiceResponseParams {
  difficulty: number // difficulty of the fish in this response
  fish_index: number // Which fish is currently being caught?
  fish_caught: boolean // is this response the one which caught the fish?
}
export interface GrammarHippoGameResponseParams extends GrammarMultipleChoiceResponseParams {
  streak: number // current streak after response
}

// Extended types to use for casting sessions, questions and responses into a type when you KNOW what type to expect.
export interface GrammarDogGameSessionInfo extends GrammarGameSessionInfo{
  responses: GrammarDogGameResponseInfo[]
}
export interface GrammarMazeGameSessionInfo extends GrammarGameSessionInfo{
  responses: GrammarMazeGameResponseInfo[]
}
export interface GrammarFishGameSessionInfo extends GrammarGameSessionInfo{
  responses: GrammarFishGameResponseInfo[]
}
export interface GrammarHippoGameSessionInfo extends GrammarGameSessionInfo{
  responses: GrammarHippoGameResponseInfo[]
}
export interface GrammarDogGameQuestionInfo extends GrammarGameQuestionInfo{
  params: GrammarMultipleChoiceQuestionParams
}
export interface GrammarMazeGameQuestionInfo extends GrammarGameQuestionInfo{
  params: GrammarMultipleChoiceQuestionParams
}
export interface GrammarFishGameQuestionInfo extends GrammarGameQuestionInfo{
  params: GrammarMultipleChoiceQuestionParams
}
export interface GrammarHippoGameQuestionInfo extends GrammarGameQuestionInfo{
  params: GrammarMultipleChoiceQuestionParams
}
export interface GrammarDogGameResponseInfo extends GrammarGameResponseInfo{
  params: GrammarDogGameResponseParams
}
export interface GrammarMazeGameResponseInfo extends GrammarGameResponseInfo{
  params: GrammarMazeGameResponseParams
}
export interface GrammarFishGameResponseInfo extends GrammarGameResponseInfo{
  params: GrammarFishGameResponseParams
}
export interface GrammarHippoGameResponseInfo extends GrammarGameResponseInfo{
  params: GrammarHippoGameResponseParams
}

/// /////////////////////
// Bracket Question Type
// Provide a sentence with ONE bracketed section and specify bracket type
// Can be used for games that identify or manipulate brackets within sentences
/// /////////////////////
export interface GrammarBracketQuestionParams {
  type: 'bracket'
  text: string // A sentence with a pair of brackets in a suitable place
  bracketType: 'bracket' | 'comma' | 'dash' | 'quote' // The bracket type to detect in the sentence
}

// Only unique response parameter is the resulting string
interface GrammarBracketResponseParams {
  answer: string
}

export interface GrammarBracketGameResponseParams extends GrammarBracketResponseParams {

}

// Extended types to use for casting sessions, questions and responses into a type when you KNOW what type to expect.
export interface GrammarBracketGameSessionInfo extends GrammarGameSessionInfo{
  responses: GrammarBracketGameResponseInfo[]
}
export interface GrammarBracketGameQuestionInfo extends GrammarGameQuestionInfo{
  params: GrammarBracketQuestionParams
}
export interface GrammarBracketGameResponseInfo extends GrammarGameResponseInfo{
  params: GrammarBracketGameResponseParams
}

/// /////////////////////
// Punctuation Question Type
// Provide a SPECIALLY FORMATTED sentence denoting tasks for proper casing and punctuation marks
// Can be used by games that identify or manipulate a sentence
// Text format: ^ = change case; # = missing punctuation; * = replace punctuation (1st character is correct - 2nd is what's shown instead)
//    e.g. "^Thomas didn#'t know much about punctuation#. But do you*?."
//    In the above example:
//      the "T" in Thomas will become lowercase in Punctuation Pirates.
//      the apostrophe in "Don't" will be missing in Punctuation Pirates.
//      the "?" at the end of the sentence will be replaced with a "." in Punctuation Pirates.
/// /////////////////////

export interface GrammarPunctuationQuestionParams {
  type: 'punctuation'
  text: string // ^ = change case; # = missing punctuation; * = replace punctuation (1st character is correct - 2nd is what's shown instead)
}

// To save time decoding the formatted question string, save the question text as a param
interface GrammarPunctuationResponseParams {
  question: string // the whole sentence as interpreted by the game component using it
  answer: string // the whole sentence as constructed by the user
}

export interface GrammarPirateGameResponseParams extends GrammarPunctuationResponseParams {

}

export interface GrammarPunctuationAnswer {
  type: PunctuationMark
  method: 'insert' | 'replace'
  string: string
}

// Extended types to use for casting sessions, questions and responses into a type when you KNOW what type to expect.
export interface GrammarPirateGameSessionInfo extends GrammarGameSessionInfo{
  responses: GrammarPirateGameResponseInfo[]
}
export interface GrammarPirateGamenQuestionInfo extends GrammarGameQuestionInfo{
  params: GrammarPunctuationQuestionParams
}
export interface GrammarPirateGameResponseInfo extends GrammarGameResponseInfo{
  params: GrammarPirateGameResponseParams
}

/**
 * Special considerations:
 * PUNCTUATIONS MARKS ARE/SHOULD BE RESTRICTED TO A SPECIFIC CHARACTER
 * APOSTROPHES: ’
 * OPEN DOUBLE QUOTE: “
 * CLOSE DOUBLE QUOTE: ”
 * DASH: —
 *
 * The games ought to use regex to replace potentially offending characters
 * The question entry form should also sanitise bad characters
 * ----Use function sanitisePunctuationMarks in grammarUtils----
 *
 */
export type PunctuationMark = 'comma' | 'period' | 'apostrophe' | 'bracket-close' | 'bracket-open' | 'capital' | 'lowercase' | 'dash' | 'exclamation' | 'question' | 'quote' | 'quote-close' | 'quote-open' | 'semicolon' | 'colon'

/// /////////////////////
// Match Question Type
// Provide a list of objects containing matching strings
// Can be used by any comparitive game, including matching pairs, trios, grouping, sorting, etc.
//    Examples:
//      Opposites: ['north', 'south']
//      Pairs: ['Mum', 'Dad']
//      Trios: ['tall', 'taller', 'tallest']
//      Grouping/Sorting: ['Vehicles', 'car', 'boat', 'plane', 'train'] (a game could know to use the first element of the array is the category?)
/// /////////////////////

export interface GrammarMatchQuestionParams {
  type: 'match'
  title: MixedContent // e.g. match the opposites
  description?: MixedContent // e.g for example north is the opposite of south
  items: GrammarMatchItem[] // array of matching items
}

export interface GrammarMatchItem {
  id: number
  // games using matching will consider all values in this array as matches. e.g. a pairs game can use any two of the values in this array, even if the array has 5 entries and consider them matches.
  matches: string[] // e.g. ['north', 'south', 'east', 'west'] or ['fast', 'adjective', 'noun'] or ['her', 'pronoun']
}

// A matching game response will happen every time a match is compared
interface GrammarMatchResponseParams {
  items: GrammarMatchItem[] // matching item or items involved in the response
  matches: string[] // strings which were matched
}

export interface GrammarPairGameResponseParams extends GrammarMatchResponseParams {

}

// Extended types to use for casting sessions, questions and responses into a type when you KNOW what type to expect.
export interface GrammarPairGameSessionInfo extends GrammarGameSessionInfo{
  responses: GrammarPairGameResponseInfo[]
}
export interface GrammarPairGameQuestionInfo extends GrammarGameQuestionInfo{
  params: GrammarMatchQuestionParams
}
export interface GrammarPairGameResponseInfo extends GrammarGameResponseInfo{
  params: GrammarPairGameResponseParams
}

/// /////////////////////
// Sentence Forming Question Type
// Provide a sentence with OPTIONAL FORMATTING
// Can be used for any game that requires the user to form a sentence
// Providing a plain sentence will work fine, but the optional formatting will help with making tailored questions
// Text Format: use curly braces ({}) to denote segments that are auto-filled. Use square brackets ([]) to group parts of a sentence together
//    Example:'{Yesterday} [I went] {to the shop} [and bought] {a new toy}'
//      In this example, in Sweet Dream Sentences, it will be presented like so: Yesterday ________ to the shop ____________ a new toy
//      Anything within the {} curly braces like {Yesterday} will appear autofilled.
//      Anything within the [] square brackets will be grouped as an answer, otherwise each word will be split individually.
/// /////////////////////

export interface GrammarSentenceQuestionParams {
  type: 'sentence'
  title?: MixedContent // prompt for what to write
  description?: MixedContent // Explanation of what to write
  text: string // use curly braces ({}) to denote segments that are auto-filled. Use square brackets ([]) to group parts of a sentence together
  falseWords: string[] // array of trap words that aren't in the sentence
  speakAnswer: boolean // Whether to speak the answer as audio
  splitPunctuation: boolean // Whether to split punctuation marks as individual pieces
}

// To save time decoding the formatted question string, save the question text as a param
export interface GrammarSentenceResponseParams {
  question: string // the whole sentence as interpreted by the game component using it
  answer: string // the whole sentence as constructed by the user
}

export interface GrammarDreamGameResponseParams extends GrammarSentenceResponseParams {

}

export interface GrammarSentenceQuestionSection {
  autoFill: boolean
  parts: string[]
}

// Extended types to use for casting sessions, questions and responses into a type when you KNOW what type to expect.
export interface GrammarDreamGameSessionInfo extends GrammarGameSessionInfo{
  responses: GrammarDreamGameResponseInfo[]
}
export interface GrammarDreamGameQuestionInfo extends GrammarGameQuestionInfo{
  params: GrammarSentenceQuestionParams
}
export interface GrammarDreamGameResponseInfo extends GrammarGameResponseInfo{
  params: GrammarDreamGameResponseParams
}

// Reporting types

export interface GrammarGameHighScore {
  ident: GrammarGameIdent
  score: number
  earnings:number
}

export interface GrammarGamesQuestionResponseData {
  question: GrammarGameQuestionInfo
  responses: GrammarGameResponseInfo[]
}

export interface GrammarGamesFishGameQuestionResponseData {
  difficulty: number
  responses: GrammarFishGameResponseInfo[]
}

export const ReportedGrammarQuestionType = ['Inappropriate', 'Wrong answer', 'Spelling mistake', 'Other'] as const
export type ReportedGrammarQuestionType = typeof ReportedQuestionType[number]

export interface GrammarQuestionReport {
  type: ReportedGrammarQuestionType
  description: string
}

export interface ReportedGrammarQuestionInfo {
  id: number
  type: ReportedGrammarQuestionType
  description: string
  created: ISO8601Date
  user_id: number
  user: string
  question: {
    id: number
    params: GrammarGameQuestionParams
    lesson_id: number
    game_ident: GrammarGameIdent
  }
  ignored: boolean
}

// Grammar Nodes Management
export interface CreateGrammarLessonRequest {
  topic_id: number
  title: string
  quiz_curriculum_node_id: number | null
}

export interface UpdateGrammarLessonRequest {
  title: string
}

export interface UpdateGrammarTopicRequest {
  title: string
}

export type GrammarGameQuestionCounts = { [key in GrammarGameIdent]?: number }

export interface GrammarLessonInfo {
  id: number
  topic_id: number
  title: string
  quiz_curriculum_node_id: number | null
  question_counts: GrammarGameQuestionCounts | null
  games?: GrammarGameIdent[]
}

export type GrammarTopicType = 'word' | 'sentence' | 'punctuation' | 'text'

export interface GrammarTopicInfo {
  id: number
  locale: Locale
  stage: number
  type: GrammarTopicType
  title: string
  quiz_curriculum_node_id: number | null
}

export type QuizCurriculumNodeGrammarLink = { lesson_id: number } | { topic_id: number } | null

export interface QuizCurriculumNodeGrammarLinkingInfo {
  id: number
  parent_id: number | null
  curriculum_id: number
  hierarchy: string
  name: string
  qualified_name: string
  url_key: string
  url_path: string
  linked: QuizCurriculumNodeGrammarLink
}

export interface GrammarNodesForLinking {
  topics: GrammarTopicInfo[]
  lessons: GrammarLessonInfo[]
  quiz_curriculum_nodes: QuizCurriculumNodeGrammarLinkingInfo[]
}
