import { UnreachableCaseError } from 'ts-essentials'
import type { FlagImages } from './flags'

export interface LocaleAudio {
  /** Where to generate data from */
  source?: 'Google' | 'Amazon'

  /** Which Locale to fall back to when not available */
  locale?: Locale
}

interface _LocaleData {
  name: string
  languageName: string | null
  emojiCode?: string
  language: string
  country: string
  spelling: boolean
  quiz: boolean
  user: boolean
  school: boolean
  currency: 'gbp' | 'usd' | 'eur' | 'nzd' | 'aud' | 'zar'
  stripe?: 'GB' | 'US'
  svg?: keyof typeof FlagImages
  audio: boolean
}

export class LocaleData {
  /** Name of the country being represented by locale */
  name: string

  /** Name of language being represented by locale */
  languageName: string | null

  /** Key of flag SVG for locale switcher. See edshed-common/i18n/flags/index.ts */
  svg: keyof typeof FlagImages | null

  /** Emoji code for locale flag. Generated from country code */
  emoji: string

  /** ISO standard format: lower case language - dash - upper case country e/g. "en-GB". Generated from language and country fields. */
  iso: string

  /** i18n format: lower case language - dash - lower case country e.g. "en-gb". Generated from language and country fields. */
  code: string

  /** Our database format: lower case language - underscore - upper case country e.g. "en_GB". Generated from language and country fields. */
  underscored: Locale

  /** ISO 639-1 language code (2 characters) i.e. en */
  language: string

  /** ISO 3166-1 alpha-2 country code (2 characters) i.e GB */
  country: string

  /** Indicates whether locale can be used for spelling lists */
  spelling: boolean

  /** Indicates whether locale can be used for quiz entities */
  quiz: boolean

  /** Indicates whether locale can be used for user accounts */
  user: boolean

  /** Indicates whether locale can be used for schools */
  school: boolean

  /** Default currency for locale */
  currency: 'gbp' | 'usd' | 'eur' | 'nzd' | 'aud' | 'zar'

  /** Stripe account to use for locale */
  stripe: 'GB' | 'US'

  /** used to generate audio clip by accent */
  audio: boolean

  constructor(data: _LocaleData) {
    this.name = data.name
    this.languageName = data.languageName
    this.language = data.language.toLowerCase()
    this.country = data.country.toUpperCase()
    this.spelling = data.spelling
    this.quiz = data.quiz
    this.user = data.user
    this.school = data.school
    this.currency = data.currency
    this.stripe = data.stripe || 'GB'
    this.svg = data.svg || null
    this.iso = `${data.language.toLowerCase()}-${data.country.toUpperCase()}`
    this.code = `${data.language.toLowerCase()}-${data.country.toLowerCase()}`
    this.underscored = `${data.language.toLowerCase()}_${data.country.toUpperCase()}` as Locale
    this.audio = data.audio // for the audioLocales array to generate audio clips in different English accents

    if (data.emojiCode) {
      this.emoji = data.emojiCode
    } else {
      const aFlagInDec = 127462
      const countryLetters = (data.emojiCode || this.country).split('')
      const firstEmoji = String.fromCodePoint(aFlagInDec + countryLetters[0].charCodeAt(0) - 'A'.charCodeAt(0))
      const secondEmoji = String.fromCodePoint(aFlagInDec + countryLetters[1].charCodeAt(0) - 'A'.charCodeAt(0))
      this.emoji = `${firstEmoji}${secondEmoji}`
    }
  }
}

export const VoiceLocale = ['en_GB', 'en_IE', 'en_AU', 'en_NZ', 'en_IN', 'en_ZA', 'en_US', 'cy_GB', 'af_ZA', 'es_ES', 'fr_FR', 'de_DE', 'pt_PT', 'pt_BR'] as const
export type VoiceLocale = typeof VoiceLocale[number]

export interface VoiceAudioSettings {
  /** Where to generate data from */
  source: 'Google' | 'Amazon'
}

// some locales are not available in Google TTS
// see https://cloud.google.com/text-to-speech/docs/voices for current list
const _audioSettings = {
  en_AU: { source: 'Google' },
  en_GB: { source: 'Amazon' },
  en_IE: { source: 'Amazon' },
  en_NZ: { source: 'Amazon' },
  en_ZA: { source: 'Amazon' },
  en_US: { source: 'Google' },
  en_IN: { source: 'Google' },
  af_ZA: { source: 'Google' },
  cy_GB: { source: 'Amazon' },
  de_DE: { source: 'Google' },
  es_ES: { source: 'Google' },
  fr_FR: { source: 'Google' },
  pt_BR: { source: 'Google' },
  pt_PT: { source: 'Google' }
} as const

export const audioSettings: Record<VoiceLocale, VoiceAudioSettings> = _audioSettings

type ExtractServiceLocales<T, S extends VoiceAudioSettings['source']> = {
  [K in keyof T]: T[K] extends { source: S } ? K : never
}[keyof T]

export type GoogleVoiceLocales = ExtractServiceLocales<typeof _audioSettings, 'Google'>
export type AmazonVoiceLocales = ExtractServiceLocales<typeof _audioSettings, 'Amazon'>

export function getVoiceName(voice: VoiceLocale) {
  switch (voice) {
    case 'en_GB':
      return 'British'
    case 'en_US':
      return 'American'
    case 'en_AU':
      return 'Australian'
    case 'en_NZ':
      return 'New Zealand'
    case 'en_IE':
      return 'Irish'
    case 'en_IN':
      return 'Indian'
    case 'en_ZA':
      return 'South African'
    case 'af_ZA':
      return 'Afrikaans (South Africa)'
    case 'cy_GB':
      return 'Welsh'
    case 'de_DE':
      return 'German'
    case 'es_ES':
      return 'Spanish'
    case 'fr_FR':
      return 'French'
    case 'pt_BR':
      return 'Portugese (Brazil)'
    case 'pt_PT':
      return 'Portugese (European)'
    default:
      throw new UnreachableCaseError(voice)
  }
}

/** Map of usable locales - add to this to create more locales */
const _localeMap = [
  ['en_GB', new LocaleData({ name: 'United Kingdom', languageName: 'British English', language: 'en', country: 'GB', spelling: true, quiz: true, user: true, school: true, currency: 'gbp', svg: 'gb', audio: true })],
  ['en_AU', new LocaleData({ name: 'Australia', languageName: null, language: 'en', country: 'AU', spelling: false, quiz: true, user: true, school: true, currency: 'aud', svg: 'au', audio: true })],
  ['en_CA', new LocaleData({ name: 'Canada', languageName: null, language: 'en', country: 'CA', spelling: false, quiz: false, user: false, school: false, currency: 'usd', svg: 'ca', audio: true })], // disabled until better currency support
  ['en_HK', new LocaleData({ name: 'China (Hong Kong)', languageName: null, language: 'en', country: 'HK', spelling: false, quiz: false, user: false, school: false, currency: 'usd', svg: 'hk', audio: false })], // disabled until better currency support
  ['en_IE', new LocaleData({ name: 'Ireland', languageName: null, language: 'en', country: 'IE', spelling: false, quiz: true, user: true, school: true, currency: 'eur', svg: 'ie', audio: true })],
  ['en_IN', new LocaleData({ name: 'India', languageName: null, language: 'en', country: 'IN', spelling: false, quiz: false, user: false, school: false, currency: 'usd', svg: 'in', audio: true })], // disabled until better currency support
  ['en_NZ', new LocaleData({ name: 'New Zealand', languageName: null, language: 'en', country: 'NZ', spelling: false, quiz: true, user: true, school: true, currency: 'nzd', svg: 'nz', audio: true })],
  ['en_PK', new LocaleData({ name: 'Pakistan', languageName: null, language: 'en', country: 'PK', spelling: false, quiz: false, user: false, school: false, currency: 'usd', svg: 'pk', audio: false })], // disabled until better currency support
  ['en_US', new LocaleData({ name: 'United States', languageName: 'American English', language: 'en', country: 'US', spelling: true, quiz: true, user: true, school: true, currency: 'usd', svg: 'us', stripe: 'US', audio: true })],
  ['en_ZA', new LocaleData({ name: 'South Africa', languageName: null, language: 'en', country: 'ZA', spelling: false, quiz: true, user: true, school: true, currency: 'zar', svg: 'za', stripe: 'GB', audio: true })],
  ['fr_FR', new LocaleData({ name: 'France', languageName: 'French', language: 'fr', country: 'FR', spelling: true, quiz: false, user: false, school: false, currency: 'eur', audio: false })],
  ['de_DE', new LocaleData({ name: 'Germany', languageName: 'German', language: 'de', country: 'DE', spelling: true, quiz: false, user: false, school: false, currency: 'eur', audio: false })],
  ['es_ES', new LocaleData({ name: 'Spain', languageName: 'Spanish', language: 'es', country: 'ES', spelling: true, quiz: false, user: false, school: false, currency: 'eur', audio: false })],
  ['cy_GB', new LocaleData({ name: 'Wales', languageName: 'Welsh', language: 'cy', country: 'GB', spelling: true, quiz: false, user: false, school: false, currency: 'gbp', emojiCode: '🏴󠁧󠁢󠁷󠁬󠁳󠁿', audio: false })],
  ['pt_PT', new LocaleData({ name: 'Portugal', languageName: 'Portuguese (Portugal)', language: 'pt', country: 'PT', spelling: true, quiz: false, user: false, school: false, currency: 'eur', audio: false })],
  ['pt_BR', new LocaleData({ name: 'Brazil', languageName: 'Portuguese (Brazil)', language: 'pt', country: 'BR', spelling: true, quiz: false, user: false, school: false, currency: 'usd', audio: false })],
  ['af_ZA', new LocaleData({ name: 'Suid Afrika', languageName: 'Afrikaans', language: 'af', country: 'ZA', spelling: true, quiz: false, user: false, school: false, currency: 'zar', audio: false })]
] as const

/** List of usable locales */

export const locales = [..._localeMap.map(l => l[1])] as const

export const DictionaryLanguage = ['en_GB', 'en_US', 'fr_FR', 'de_DE', 'es_ES', 'cy_GB', 'pt_PT', 'pt_BR', 'af_ZA'] as const
export type DictionaryLanguage = typeof DictionaryLanguage[number]

export const SpellingEducationalRegion = ['en_GB', 'en_US', 'en_ZA'] as const
export type SpellingEducationalRegion = typeof SpellingEducationalRegion[number]

/** List of usable Locale codes */
export const Locale = _localeMap.map(l => l[0])
export type Locale = typeof _localeMap[number][0]

export const SvgFlagLocale = ['en_GB', 'en_AU', 'en_CA', 'en_HK', 'en_IE', 'en_IN', 'en_NZ', 'en_PK', 'en_US', 'en_ZA'] as const
export type SvgFlagLocale = typeof SvgFlagLocale[number]

const flagSvgMap: Record<SvgFlagLocale, string> = {
  en_AU: 'au.svg',
  en_CA: 'ca.svg',
  en_GB: 'gb.svg',
  en_HK: 'hk.svg',
  en_IE: 'ie.svg',
  en_IN: 'in.svg',
  en_NZ: 'nz.svg',
  en_PK: 'pk.svg',
  en_US: 'us.svg',
  en_ZA: 'za.svg'
}

export function getSvgForFlaggableLocale(fl: SvgFlagLocale) {
  return flagSvgMap[fl]
}

const languageMap: Record<DictionaryLanguage, string> = {
  af_ZA: 'Afrikaans',
  cy_GB: 'Welsh',
  de_DE: 'German',
  en_GB: 'British English',
  en_US: 'American English',
  es_ES: 'Spanish',
  fr_FR: 'French',
  pt_BR: 'Portugese (Brazil)',
  pt_PT: 'Portugese (Portugal)'
}

const flagMap: Record<DictionaryLanguage | VoiceLocale, string> = {
  af_ZA: '🇿🇦',
  cy_GB: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
  de_DE: '🇩🇪',
  en_AU: '🇦🇺',
  en_GB: '🇬🇧',
  en_IE: '🇮🇪',
  en_IN: '🇮🇳',
  en_NZ: '🇳🇿',
  en_US: '🇺🇸',
  en_ZA: '🇿🇦',
  es_ES: '🇪🇸',
  fr_FR: '🇫🇷',
  pt_BR: '🇧🇷',
  pt_PT: '🇵🇹'
}

const languageVoiceMap: Record<DictionaryLanguage, VoiceLocale[]> = {
  af_ZA: ['af_ZA'],
  cy_GB: ['cy_GB'],
  de_DE: ['de_DE'],
  en_GB: ['en_GB', 'en_IE', 'en_AU', 'en_NZ', 'en_IN', 'en_ZA'],
  en_US: ['en_US'],
  es_ES: ['es_ES'],
  fr_FR: ['fr_FR'],
  pt_BR: ['pt_BR'],
  pt_PT: ['pt_PT']
}

export function getPreferredVoiceForLanguage(language: DictionaryLanguage) {
  return languageVoiceMap[language][0]
}

export function getLanguageName(language: DictionaryLanguage) {
  return languageMap[language]
}

export function getLocaleEmojiFlag(loc: DictionaryLanguage | VoiceLocale) {
  return flagMap[loc]
}

export function getVoicesForLanguage(lang: DictionaryLanguage) {
  return languageVoiceMap[lang]
}

export function getLocaleFromUnderscored(underscored: string): LocaleData {
  const locale = locales.find(l => l.underscored === underscored)
  return locale || locales.find(l => l.code === 'en-gb')!
}

export function getLocaleFromCode(code: string): LocaleData {
  const locale = locales.find(l => l.code === code)
  return locale || locales.find(l => l.code === 'en-gb')!
}

export function getLocaleFromCountryCode(countryCode: string): LocaleData {
  countryCode = countryCode.toUpperCase()

  const locale = locales.find(l => l.country === countryCode)

  if (!locale) {
    // in the future we might try to match on language - but not now - just default to GB.
    // const localeCountry = locale.iso.split('-')[0]
    return locales.find(l => l.code === 'en-gb')!
  }

  return locale
}

export function checkIsLocale(locale: string): boolean {
  return (
    locales.find(l => l.code === locale.toLocaleLowerCase()) !== undefined // Search locales i.e. "en-gb"
    || locales.find(l => l.language === locale.toLowerCase()) !== undefined // Search locale languages i.e. "en"
  )
}
