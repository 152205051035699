import { PhonicsGraphemeId, PhonicsGraphemeInfo } from '../../../scripts/phonics/graphemes'
import { Bit, FileUpload, StoredImage, Locale } from '../common'
import { ArrayElement, DictionaryInfo, ISO8601Date, PhonicsGameIdent, Pupil, UserModelBase } from '..'

export * from './phonicsGames'

export const PhonicsComprehensionScore = [1, 2, 3] as const
export type PhonicsComprehensionScore = ArrayElement<typeof PhonicsComprehensionScore>

export interface PhonicsScreeningGroup {
  id: number,
  school_group_id: number,
  user_id: number,
  group_name: string
  parent_id: number | null
}

export interface AddAssessmentRequest {
  pupil_id: number
  element_id: PhonicsGraphemeId | number
  element_type: PhonicsAssessmentType
  score: PhonicsComprehensionScore
  comment: string | null
}

export interface AddPhonicsAssessmentData {
  pupil_id: number
  teacher_id: number
  results: readonly {
    element_id: PhonicsGraphemeId | number
    element_type: PhonicsAssessmentType
    score: PhonicsComprehensionScore
    comment: string | null
    batch_id?: number
  }[]
}

export interface AddScreeningAssessmentData {
  pupil_id: number
  teacher_id: number
  results: readonly {
    word: {
      dictionary_id: number
      create_assessment: boolean // should we add a row to assessment table for the word as well as the grapheme
      score: PhonicsComprehensionScore
      comment: string | null
    }
    graphemes: readonly {
      element_id: PhonicsGraphemeId
      score: PhonicsComprehensionScore
      comment: string | null
    }[]
  }[],
  screening: boolean
  session_id?: number
  score?: number
  passed?: Bit
}

export interface PhonicsAssessmentInfo {
  id: number
  pupil_id: number
  pupil_username: string
  pupil_display_name: string
  teacher_id: number
  teacher_username: string
  teacher_display_name: string
  element_id: PhonicsGraphemeId | number
  element_type: PhonicsAssessmentType
  score: PhonicsComprehensionScore
  date: ISO8601Date
}

export interface PhonicsWordAssessmentInfo extends PhonicsAssessmentInfo {
  word: string
}

export interface PhonicsComprehensionInfo {
  element_id: PhonicsGraphemeId | number
  element_type: PhonicsAssessmentType
  score: PhonicsComprehensionScore
  created?: ISO8601Date
}

export interface PhonicsUserComprehensionInfo {
  name: string // name added back in
  user_id: number
  username: string
  display_name: string
  comprehension: PhonicsComprehensionInfo[]
}

export interface PhonicsGroupComprehensionInfo {
  group_id: number
  group_name: string
  pupils: PhonicsUserComprehensionInfo[]
}

export interface PhonicsPurchases {
  purchased_packs: number
  assigned_packs: number
}

export interface PhonicsScreeningQuestion {
  word: DictionaryInfo
  section: 0 | 1 | 2 | 3
}

export interface PhonicsAssessment {
    mode: 'gpc' | 'word' | 'screening' | 'hfw';
    pupil: Pupil | null;
    graphemes?: PhonicsGraphemeInfo[];
    words?: PhonicsScreeningSessionWord[];
    listName?: string;
    session?: PhonicsScreeningSession;
    sessionId?: number
}

export interface PhonicsScreeningSession {
  id: number
  name: string
  teacher_id: number
  created: ISO8601Date
  assessments_played?: number
  list_id?: number
  users?: PhonicsSessionsToUser[],
  words?: PhonicsScreeningSessionWord[]
}

export interface PhonicsScreeningSessionWord {
    session_id: number,
    section: number,
    section_index: number
    word: DictionaryInfo,
    dictionary_id: number
    practice_word: Bit
}

export interface PhonicsScreeningSessionWordSimple {
  section: number,
  section_index: number,
  session_id: number,
  practice_word: Bit,
  dictionary_id: number
}

export interface PhonicsScreeningSessionData {
  id: number,
  name: string,
  username: string
  display_name: string
  score: number | null
  passed: boolean
  assessments: PhonicsScreeningWordList[]
  groups?: PhonicsScreeningGroup[]
}

export interface PhonicsScreeningWordList {
  id: number,
  score: PhonicsComprehensionScore,
  element_id: PhonicsGraphemeId,
  session_id: number | null,
  dictionary_id: number,
  word: string,
  practice_word: Bit
}

export interface PhonicsScreeningWordAssessment {
  id: number
  dictionary_id: number
  element_id: PhonicsGraphemeId | number
  element_type: PhonicsAssessmentType
  score: PhonicsComprehensionScore
  comment: string | null
  batch_id: number | null
  session_id: number | null,
  pupil_id: number,
  created?: ISO8601Date
}

export interface PhonicsSessionsToUser {
  id: number,
  session_id: number
  user_id: number
  score: number | null
  passed: Bit
}

export const PhonicsAssessmentType = ['gpc', 'element', 'word', 'hfw'] as const
export type PhonicsAssessmentType = ArrayElement<typeof PhonicsAssessmentType>

export type PhonicsSessionUser = UserModelBase & PhonicsSessionsToUser

export interface PhonicsHomeworkOptions {
  grapheme: PhonicsGraphemeId | null
  game: PhonicsGameIdent | null
}

export interface DecodableBookStructure {
  id: number
  title: string
  children: DecodableBookStructure[]
  books: DecodableBookInfo[]
}

export interface DecodableBookInfo {
  name: string
  lesson_ident: string,
  image: StoredImage | null
}

export interface PhonemesInfo {
  id: number
  locale: Locale
  code: string
  name: string
  ipa: string | null
}

export interface AddPhonemesRequest {
  locale: Locale
  code: string
  name: string
  ipa: string | null
  file: FileUpload | null
}

export interface EditPhonemesRequest {
  code?: string
  name?: string
  ipa?: string | null
  file?: FileUpload | null
}
