import { ArrayElement, ISO8601Date } from '..'

export const WondeExclusionType = ['group', 'class', 'pupil', 'staff'] as const
export type WondeExclusionType = ArrayElement<typeof WondeExclusionType>

export interface WondeExclusionToAdd {
  type: WondeExclusionType
  wonde_id: string
}

export interface WondeExclusionInfo {
  id: number
  school_id: number
  type: WondeExclusionType
  wonde_id: string
}

export interface RawWondeDate {
  date: ISO8601Date,
  timezone: string
  timezone_type: number
}

export interface RawWondeAddress {
  apartment: string | null
  country: string | null
  county: string | null
  district: string | null
  house_name: string | null
  house_number: string | null
  postcode: string | null
  street: string | null
  town: string | null
}

export interface RawWondeAddressGroup {
  home: RawWondeAddress
  postal: RawWondeAddress
  work: RawWondeAddress
}

export interface RawWondeEmailGroup {
  email: string | null
  home: string | null
  primary: string | null
  work: string | null
}

export interface RawWondePhoneGroup {
  home: string | null
  mobile: string | null
  phone: string | null
  primary: string | null
  work: string | null
}

export interface RawWondeContactDetails {
  data: {
    addresses: RawWondeAddressGroup
    emails: RawWondeEmailGroup
    phones: RawWondePhoneGroup
    salutation: string | null
  }
}

export interface RawWondeClass {
  alternative: string | null
  code: string | null
  created_at: RawWondeDate
  description: string
  id: string
  mis_id: string
  name: string
  subject: string
  updated_at: RawWondeDate
}

export interface RawWondeGroup {
  code: string
  created_at: RawWondeDate
  description: string
  division: string | null
  id: string
  mis_id: string
  name: string
  notes: string | null
  type: string
  updated_at: RawWondeDate
}

export interface RawWondeUser {
  classes: {
    data: RawWondeClass[]
  }
  contact_details: RawWondeContactDetails
  created_at: RawWondeDate
  date_of_birth: RawWondeDate | null
  forename: string
  gender: string | null
  groups: {
    data: (RawWondeGroup & {
      meta: {
        end_date: RawWondeDate
        start_date: RawWondeDate
      }
    })[]
  }
  id: string
  initials: string
  legal_forename: string | null
  legal_surname: string | null
  middle_names: string | null
  mis_id: string
  surname: string
  updated_at: RawWondeDate
  upi: string
}

export interface RawWondePupil extends RawWondeUser {
  education_details: {
    data: {
      admission_date: RawWondeDate | null
      leaving_date: RawWondeDate | null
      local_upn: string | null
      upn: string | null
    }
  }
}

export interface RawWondeStaff extends RawWondeUser {
  title: string
  employment_details: {
    data: {
      current: boolean
      employment_end_date: RawWondeDate | null
      employment_start_date: RawWondeDate | null
      teaching_staff: boolean
    }
  }
}

export interface WondePresyncInfo {
  groups: RawWondeGroup[]
  classes: RawWondeClass[]
  staff: RawWondeStaff[]
  pupils: RawWondePupil[]
  invalid_staff: RawWondeStaff[]
  problems: WondeErrors
}

export interface WondeErrors {
  upn: WondeUPNError[]
}

export interface WondeUPNError {
  name: string
  upn: string
  schools: number[]
}
