






























// @ts-ignore
import { extname } from 'path'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { StoredImage } from '../api'
import ImageModal from './ImageModal.vue'

/** Example usage: <ImageInput :record="myRecord" field="image" /> */
@Component({ })
export default class ImageInput extends Vue {
  @Prop({ required: true })
  private readonly record!: any

  @Prop({ required: true })
  private readonly field!: string

  @Prop({ required: false })
  private readonly label: string | undefined

  @Prop({ required: false, default: false })
  private readonly disabled!: boolean

  @Prop({ required: false, default: false }) // Styles the component to preview the image more prominently
  private readonly preview!: boolean

  @Prop({ required: false, default: null })
  private readonly sizeLimit!: number | null

  private dropFile: File | null = null
  private imagePreview: string = ''

  get image (): StoredImage | null {
    return this.record[this.field]
  }

  formatBytes (bytes: number, decimals: number = 2) {
    if (bytes === 0) { return '0 Bytes' }
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / k ** i).toFixed(dm)) + ' ' + sizes[i]
  }

  public mounted () {
    this.$watch('dropFile', (file: File | null) => {
      if (file === null) {
        this.imagePreview = ''
        return
      }

      if (this.sizeLimit && file.size > this.sizeLimit) {
        this.$emit('error', `File size too large - minimum of ${this.formatBytes(this.sizeLimit)}`)
        this.dropFile = null
        return
      }
      const reader = new FileReader()

      reader.readAsDataURL(file)

      reader.addEventListener('load', () => {
        this.setNewField({
          data: reader.result as string,
          extname: extname(file.name)
        })
        if (this.dropFile) {
          if (['image/jpeg', 'image/png', 'image/gif'].includes(this.dropFile.type)) {
            this.imagePreview = URL.createObjectURL(this.dropFile)
            this.$emit('preview', this.imagePreview)
          }
        }

        this.$emit('image-ready')
      })
    })
  }

  private setNewField (image: any) {
    this.record[this.field] = null
    const newFieldName = `new_${this.field}`
    this.record[newFieldName] = image
  }

  public onClear () {
    this.record[this.field] = null
    this.setNewField(null)
  }

  private showPreview () {
    this.$buefy.modal.open({
      component: ImageModal,
      props: {
        title: this.label ? `Preview for ${this.label}` : 'Preview',
        image: this.image
      },
      hasModalCard: true,
      events: { },
      onCancel: () => { }
    })
  }
}
