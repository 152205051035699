import { setApiToken, UserModel } from '@/edshed-common/api'
import { ServerEnv } from '@/edshed-common/servers/types'
import { State } from './types'

export default {
  TOGGLE_LOADING (state: State) {
    state.callingAPI = !state.callingAPI
  },
  TOGGLE_SEARCHING (state: State) {
    state.searching = (state.searching === '') ? 'loading' : ''
  },
  SET_USER (state: State, user: UserModel) {
    state.user = user
  },
  SET_TOKEN (state: State, token: string) {
    const expiry = new Date(Date.now() + (1000 * 60 * 60 * 24 * 90))
    console.log('EXPIRY: ' + expiry.toUTCString())
    if (process.env.NODE_ENV === 'development') {
      document.cookie = `_session-token_=${token};path=/;domain=localhost;`
    } else {
      document.cookie = `_session-token_=${token};path=/;domain=.edshed.com;expires=${expiry.toUTCString()}`
    }
    state.token = token
    setApiToken(token)
  },
  SET_API_ENV (state: State, env: ServerEnv) {
    state.api_environment = env
  }
}
