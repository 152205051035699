import { ISO8601Date } from './common'
import { BadgeKey, BadgeRecord } from './badges'

export const ChampionBadgeOption = ['All Badges', 'Completed Only'] as const
export type ChampionBadgeOption = typeof ChampionBadgeOption[number]

export interface ChampionFilters {
  badgeType?: BadgeKey,
  completedOnly: ChampionBadgeOption
}

export interface ChampionModel {
  username: string
  email: string | null
  badgeStarted: ISO8601Date
  badgeEarned: ISO8601Date | null
  progress: number
  badge: BadgeRecord
  schools: { school_name: string, id: number }[]
}
