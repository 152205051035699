import { DeepReadonly, UnreachableCaseError } from 'ts-essentials'
import { SubscriptionMeteredEntity } from '../../../subscriptionPackages'
import { ArrayElement, BullJobStatus, ImageUpload, ISO8601Date, StoredImage } from '../common'
import { Currency } from '../currency'
import { SchoolOrgType } from '../school'
import { UserPermissionKeys, UserModel, SchoolPermissionKeys } from '../user'
import { StripeInvoiceAccountRegion, Subscription, SubscriptionGroup } from '.'

export const SubscriptionProductType = ['spelling', 'number', 'phonics', 'phonics_digital', 'phonics_intervention', 'phonics_supplemental', 'spag', 'litshed', 'freemium'] as const
export type SubscriptionProductType = ArrayElement<typeof SubscriptionProductType>

export const SubscriptionPlanPriceType = ['fixed', 'graduated', 'volume'] as const
export type SubscriptionPlanPriceType = typeof SubscriptionPlanPriceType[number]

export const SubscriptionPlanCycleType = ['monthly', 'yearly'] as const
export type SubscriptionPlanCycleType = typeof SubscriptionPlanCycleType[number]

export interface SubscriptionPlanPriceTier {
  flat_amount?: string
  unit_amount?: string
  up_to: number | null
}

export interface AddSubscriptionProductRequest {
  title: string
  product_type: SubscriptionProductType
  new_image: ImageUpload
}

export type EditSubscriptionProductRequest = Partial<AddSubscriptionProductRequest>

export interface SubscriptionProductInfo {
  id: number
  title: string
  product_type: SubscriptionProductType
  image: StoredImage | null
  stripe_id_gb: string | null
  stripe_id_us: string | null
}

export interface AddSubscriptionProductScopeRequest {
  title: string
  org_types: SchoolOrgType[]
}

export type EditSubscriptionProductScopeRequest = Partial<AddSubscriptionProductScopeRequest>

export interface SubscriptionProductScopeInfo {
  id: number
  title: string
  product_id: number
  org_types: SchoolOrgType[]
}

export interface GetSubscriptionProductScopesFilters {
  product_id: number
}

export function getProductStripeFieldForRegion (region: StripeInvoiceAccountRegion) {
  switch (region) {
    case 'GB':
      return 'stripe_id_gb'
    case 'US':
      return 'stripe_id_us'
    default:
      throw new Error('Region not supported')
  }
}

export interface AddSubscriptionPlanRequest {
  sku: string
  parent_id: number | null
  price_type: SubscriptionPlanPriceType
  unit_amount: string | null
  price_tiers: SubscriptionPlanPriceTier[] | null
  currency: Currency
  account_region: StripeInvoiceAccountRegion
  cycle: SubscriptionPlanCycleType
  domestic: boolean
  base: boolean
  metered_entity: SubscriptionMeteredEntity | null
  teachers: number | null
  pupils: number | null
  classes: number | null
  min: number | null
  max: number | null
  add_on_id: string | null
  bundles_with: number[]
  permissions: PlanPermissions
}

export type EditSubscriptionPlanRequest = Partial<Pick<AddSubscriptionPlanRequest, 'metered_entity' | 'teachers' | 'pupils' | 'classes' | 'max' | 'min' | 'bundles_with' | 'permissions'>>

export type AddPlanActionRequest = AddPlanActionRequestWithout | AddPlanActionRequestWith

export interface AddPlanActionRequestWithout {
  date: Date | null
  action: Exclude<SubscriptionActionType, 'migrate_renewal'>
  batch_id: string | null
}

export interface AddPlanActionRequestWith {
  date: Date | null
  action: Exclude<SubscriptionActionType, 'activate' | 'deprecate'>
  batch_id: string | null
  to_id: number | null,
  migration_quantity: number | null
  behaviour: SubscriptionActionBehaviour
}

export function planActionRequestNeedsTarget (req: AddPlanActionRequest): req is AddPlanActionRequestWith {
  return req.action === 'migrate_renewal'
}

export interface SubscriptionPlanInfo {
  id: number
  parent_id: number | null
  sku: string
  price_type: SubscriptionPlanPriceType
  unit_amount: string | null
  price_tiers: SubscriptionPlanPriceTier[] | null
  currency: Currency
  product_id: number
  product: SubscriptionProductInfo
  account_region: StripeInvoiceAccountRegion
  stripe_id: string
  scope_id: number
  scope: SubscriptionProductScopeInfo
  cycle: SubscriptionPlanCycleType
  domestic: boolean
  base: boolean
  active: boolean
  created: ISO8601Date
  children: SubscriptionPlanInfo[]
  add_on_id: string | null,
  pupils: number | null
  teachers: number | null
  classes: number | null
  min: number | null
  max: number | null
  metered_entity: SubscriptionMeteredEntity | null
  bundle_children: SubscriptionPlanBundleStub[]
  bundle_parents: SubscriptionPlanBundleStub[]
  permissions: PlanPermissions
}

export interface SubscriptionPermissionRule<E extends UserPermissionKeys | SchoolPermissionKeys> {
  name: E
  paid: boolean
  trial: boolean
}

export interface PlanPermissions {
  pupil_permissions: SubscriptionPermissionRule<UserPermissionKeys>[]
  teacher_permissions: SubscriptionPermissionRule<UserPermissionKeys>[]
  class_permissions: SubscriptionPermissionRule<UserPermissionKeys>[]
  school_permissions: SubscriptionPermissionRule<SchoolPermissionKeys>[]
}

export interface SubscriptionPlanBundleStub {
  id: number
  sku: string
  product_name: string
  scope_name: string
  scope_id: number
  product_id: number
  product_type: SubscriptionProductType
}

export interface GetSubscriptionPlansFilters {
  product_id?: number | number[]
  product_type?: SubscriptionProductType
  scope_id?: number
  org_type?: SchoolOrgType
  parent_id?: number | number[] | null
  id?: number | number[]
  currency?: Currency
  cycle?: SubscriptionPlanCycleType
  domestic?: boolean
  active?: boolean
  stripe_id?: string | string[]
}

export interface SubscriptionActivateAction {
  action: 'activate'
  plan_id: number
  date: ISO8601Date
  batch_id: string
}

export interface SubscriptionRenewalMigrationAction {
  action: 'migrate_renewal'
  plan_id: number
  to_id: number | null
  date: ISO8601Date,
  batch_id: string
  migration_quantity: number | null
  behaviour: SubscriptionActionBehaviour
}

export const SubscriptionActionBehaviour = ['keep', 'remove'] as const
export type SubscriptionActionBehaviour = typeof SubscriptionActionBehaviour[number]

export interface SubscriptionDeprecateAction {
  action: 'deprecate'
  plan_id: number
  date: ISO8601Date
  batch_id: string
}

export interface SubscriptionActionInfo {
  queue_data?: {
    action: SubscriptionAction
    error?: string | undefined,
    status: BullJobStatus
    job_id: string
  },
  db_data?: {
    plan_id: number
    to_id: number | null
    date: ISO8601Date,
    type: SubscriptionAction['action']
    created: ISO8601Date,
    job_id: string
    status: BullJobStatus,
    migration_quantity: number | null
    behaviour: SubscriptionActionBehaviour | null
    batch_id: string
  }
  error?: string | undefined,
  plan_id: number
  to_id?: number | null
  date: ISO8601Date,
  type: SubscriptionAction['action']
  created: ISO8601Date,
  job_id: string
  status: BullJobStatus,
  migration_quantity?: number | null
  behaviour?: SubscriptionActionBehaviour | null
  batch_id: string
  plan?: SubscriptionPlanInfo
  to_plan?: SubscriptionPlanInfo
}

export type SubscriptionAction = SubscriptionActivateAction | SubscriptionRenewalMigrationAction | SubscriptionDeprecateAction

export function actionNeedsTarget (action: SubscriptionAction): action is SubscriptionRenewalMigrationAction {
  return action.action === 'migrate_renewal'
}

export const SubscriptionActionType = ['activate', 'migrate_renewal', 'deprecate'] as const
export type SubscriptionActionType = typeof SubscriptionActionType[number]

export interface GetSubscriptionPlanActionsFilters {
  type?: SubscriptionActionType | SubscriptionActionType[]
  job_id?: string | string[]
  status?: null | BullJobStatus | BullJobStatus[]
  plan_id?: number | number[]
  to_id?: number | number[]
}

export function getGraduatedPrice (num: number, tiers: SubscriptionPlanPriceTier[]) {
  // sort ascending, just in case
  const sortedTiers = [...tiers].sort((a, b) => {
    if (!a.up_to) { return 1 }
    if (!b.up_to) { return -1 }
    if (a.up_to < b.up_to) { return -1 }
    if (a.up_to > b.up_to) { return 1 }
    return 0
  })

  let total = 0

  for (const [i, tier] of sortedTiers.entries()) {
    // is this the highest applicable tier for this quantity
    const isHighestTier = tier.up_to === null || tier.up_to >= num

    if (tier.flat_amount) {
      total += parseFloat(tier.flat_amount)
    }

    if (tier.unit_amount) {
      const previousTier = i === 0 ? undefined : tiers[i - 1]

      const startQty = previousTier ? previousTier.up_to! : 0

      const numAtThisTier = isHighestTier ? num - startQty : tier.up_to! - startQty

      total += numAtThisTier * parseFloat(tier.unit_amount)
    }

    if (isHighestTier) {
      break
    }
  }

  return total
}

export function getVolumePrice (num: number, tiers: SubscriptionPlanPriceTier[]) {
  // sort ascending, just in case
  const sortedTiers = [...tiers].sort((a, b) => {
    if (!a.up_to) { return 1 }
    if (!b.up_to) { return -1 }
    if (a.up_to < b.up_to) { return -1 }
    if (a.up_to > b.up_to) { return 1 }
    return 0
  })

  for (const tier of sortedTiers) {
    // is this the highest applicable tier for this quantity
    const isHighestTier = tier.up_to === null || tier.up_to >= num

    if (!isHighestTier) {
      continue
    }

    const flatAmount = tier.flat_amount ? parseFloat(tier.flat_amount) : 0
    const unitAmount = tier.unit_amount ? parseFloat(tier.unit_amount) : 0

    return flatAmount + unitAmount * num
  }

  return 0
}

export function getVolumeUnitPrice (num: number, tiers: SubscriptionPlanPriceTier[]) {
  // sort ascending, just in case
  const sortedTiers = [...tiers].sort((a, b) => {
    if (!a.up_to) { return 1 }
    if (!b.up_to) { return -1 }
    if (a.up_to < b.up_to) { return -1 }
    if (a.up_to > b.up_to) { return 1 }
    return 0
  })

  for (const tier of sortedTiers) {
    // is this the highest applicable tier for this quantity
    const isHighestTier = tier.up_to === null || tier.up_to >= num

    if (!isHighestTier) {
      continue
    }

    const unitAmount = tier.unit_amount ? parseFloat(tier.unit_amount) : 0

    if (unitAmount === 0) {
      const fixedAmount = tier.flat_amount ? parseFloat(tier.flat_amount) : 0
      return fixedAmount / num
    }

    return unitAmount
  }

  return 0
}

export function getPriceForPlan (num: number, plan: SubscriptionPlanInfo) {
  if (plan.price_type === 'fixed') {
    return (plan.unit_amount ? parseFloat(plan.unit_amount) : 0) * num
  } else if (plan.price_type === 'graduated') {
    return getGraduatedPrice(num, plan.price_tiers ?? [])
  } else if (plan.price_type === 'volume') {
    return getVolumePrice(num, plan.price_tiers ?? [])
  } else {
    throw new UnreachableCaseError(plan.price_type)
  }
}

export function getPriceForCombination (basePlan: SubscriptionPlanInfo, addOns: SubscriptionPlanInfo[], qty: number) {
  return [basePlan, ...addOns].reduce((tot, p) => tot + getPriceForPlan(qty, p), 0)
}

export interface StartTrialRequest {
  pupil_seats: number | null
  teacher_seats: number | null
  base_plan_id: number
  add_on_plan_ids: number[]
  days?: number
}

export interface StartSubscriptionRequest {
  coupon?: string | null
  po_number?: string | null
  quantity: number
  base_plan_id: number
  add_on_ids: number[]
  payment_method?: string | null
  trial_end?: number | null
}

interface StartSubscriptionResponseSuccessful {
  intent_secret: null
  subscription: Subscription
  user: UserModel
}

interface StartSubscriptionResponseRequiresAction {
  intent_secret: string
  subscription: null
  user: UserModel
}

export type SuperuserAddSubscriptionResponse = SuperuserAddSubscriptionResponseSuccessful | SuperuserAddSubscriptionResponseRequiresAction

interface SuperuserAddSubscriptionResponseSuccessful {
  intent_secret: null
  subscription: Subscription
}

interface SuperuserAddSubscriptionResponseRequiresAction {
  intent_secret: string
  subscription: null
}

export type StartSubscriptionResponse = StartSubscriptionResponseSuccessful | StartSubscriptionResponseRequiresAction

export interface GetUpgradeCostParams {
  quantity: number
  base_plan_id: number
  add_on_plan_ids: number[]
  reset_billing?: boolean
  prorate?: boolean
}

export interface UpgradeSubscriptionRequest {
  po_number?: string | null
  quantity: number
  base_plan_id: number
  add_on_plan_ids: number[]
  payment_method?: string | null
  reset_billing?: boolean
  prorate?: boolean
}

export interface ConvertSubscriptionRequest {
  payment_method: string | null
  po_number?: string | null
}

export interface UpgradeSubscriptionResponse {
  user: DeepReadonly<UserModel>
  intent_secret: string | null
  subscription: Subscription
}

export interface ScheduledSubscriptionInfo {
  id: string
  quantity: number
  date: ISO8601Date
  plans: SubscriptionPlanInfo[]
}

export interface SuperuserAddBackdatedSubscriptionRequest {
  add_on_plan_ids: number[]
  base_plan_id: number
  coupon?: string | null
  quantity?: number
  expiry_date: number
  payment_method: string | null
  charge_type: 'pay-full' | 'pay-remaining'
  po_number?: string | null
}

export interface SuperuserAddFutureSubscriptionRequest {
  add_on_plan_ids: number[]
  base_plan_id: number
  coupon?: string | null
  quantity?: number
  start_date: number
  payment_method: string | null
  po_number?: string | null
}

export interface SuperuserAddFullSubscriptionRequest {
  add_on_plan_ids: number[]
  base_plan_id: number
  coupon?: string | null
  quantity?: number
  payment_method: string | null
  po_number?: string | null
}

export interface SuperuserAddTrialSubscriptionRequest {
  base_plan_id: number
  add_on_plan_ids: number[]
  pupil_seats: number | null
  teacher_seats: number | null
  classes: number | null
  days?: number
}

export interface SuperuserAddFreeSubscriptionRequest {
  add_on_plan_ids: number[]
  base_plan_id: number
  coupon?: string | null
  expiry: Date | null
  quantity?: number
  post_stripe: boolean
  send_invoice: boolean
  payment_method: string | null
}

export function planCycleToMomentPeriod (cycle: SubscriptionPlanCycleType) {
  switch (cycle) {
    case 'yearly':
      return 'year'
    case 'monthly':
      return 'month'
    default:
      throw new UnreachableCaseError(cycle)
  }
}

export function groupFromProductType (type: SubscriptionProductType): SubscriptionGroup {
  switch (type) {
    case 'litshed':
      return 'litshed'
    case 'number':
    case 'phonics':
    case 'spelling':
    case 'spag':
    case 'phonics_intervention':
    case 'phonics_supplemental':
    case 'phonics_digital':
    case 'freemium':
      return 'edshed'
    default:
      throw new UnreachableCaseError(type)
  }
}

export function isPersonal (plan: SubscriptionPlanInfo) {
  return !plan.scope.org_types.includes('school') && !plan.scope.org_types.includes('district')
}

export type SubscriptionForPlan = Subscription & { school_name: string }
