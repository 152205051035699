import { SubscriptionLevelType, SubscriptionPlanAccountRegion, SubscriptionPlanAccountType, SubscriptionPlanCurrency, SubscriptionPlanGroup, SubscriptionPlanId, SubscriptionPlanPeriod } from '../../../subscriptionPackages'
import { ArrayElement, Bit, FileUpload, ISO8601Date } from '../common'
import { CountryCode, CountryInfo, edShedCountryToIso3166 } from '../country'
import { Currency } from '../currency'
import { SalesSourceDepts, SalesPerson } from '../sales'
import { SchoolOrgType } from '../school'
import { SubscriptionPlanInfo, SubscriptionPlanPriceType, SubscriptionProductType } from './plans'

export const DomesticCountry = ['GB', 'GG', 'JE', 'IM', 'IE', 'AE', 'QA', 'EG', 'IQ', 'SA', 'BH', 'OM', 'IR', 'KW', 'JO'] as const
export type DomesticCountry = ArrayElement<typeof DomesticCountry>
export * from './plans'
export * from './cancellation'
export * from './invoices'

export function isDomesticCountry (country: CountryInfo) {
  const code = country.code

  const isoCode = edShedCountryToIso3166(code)

  return DomesticCountry.includes(isoCode as DomesticCountry)
}

export const SubscriptionGroup = ['edshed', 'litshed'] as const
export type SubscriptionGroup = ArrayElement<typeof SubscriptionGroup>
export interface Subscription {
  active: number
  baseCost: number
  cancellation_date: ISO8601Date | null
  creation_timestamp: ISO8601Date
  currency: Currency
  discount: number
  expiry: ISO8601Date
  id: number
  group: SubscriptionGroup
  premium: Bit
  paid: number
  type: SubscriptionType
  spelling: Bit
  number: Bit
  phonics: Bit
  pupil_seats: number | null
  teacher_seats: number | null
  classes: number | null
  assigned_seats: number
  assigned_classes: number
  assigned_teachers: number
  subscription_id: string | null
  school: Bit
  school_id: number
  is_metered: boolean
  permissions: SubscriptionPermissions
  po_number: string // this is the po number that will be used on renewal
  parent_id: number | null
  scheduled_change: ScheduledSubscriptionChange | null
  base_plan: SubscriptionPlanInfo
  add_on_plans: SubscriptionPlanInfo[]
  infer_teachers_by_group: boolean
  cycles_left: number | null
}

export interface ScheduledSubscriptionChange {
  id: string
  cost: number
  tax: number
  date: number
  base_plan_stripe_id: string
  add_on_plans_stripe_ids: string[]
  qty: number
}

export interface SubscriptionPermissions {
  can_upgrade: boolean
  can_downgrade: boolean
}

export interface StripeInvoiceInfo {
  id: number
  invoice_id: string // stripe
  subscription_id: string // stripe
  customer_id: string // stripe
  paid: boolean
  date: ISO8601Date
  expiry?: ISO8601Date,
  cancellation_date?: ISO8601Date | null,
  currency: Currency
  base_cost: number
  discount: number
  tax: number
  amount: number
  currency_final: string
  amount_final: number
  credit_applied: number
  po_number: string
  void: boolean
  payment_date: ISO8601Date | null
  refund_date: ISO8601Date | null
  status: StripeInvoiceStatus
  test: boolean
  client_ref: string
  reconciled: boolean
  method: StripeInvoicePaymentMethod | null
  payment_ref: string
  xero_invoice_id: string | null
  account_region: StripeInvoiceAccountRegion
  remittance_url: string | null
  remittance_date: ISO8601Date | null
  remittance_upload_date: ISO8601Date | null
  remittance_upload_user: number | null
  billing_reason: StripeInvoiceBillingReason | null
  source_person: SalesPerson | null
  source_dept: SalesSourceDepts | null
  school: StripeInvoiceSchoolInfo
  items: StripeInvoiceItemInfo[]
  plan: SubscriptionPlanId | null // old system
  quantity: number // old system
  shareable_ident: string | null
}

export interface StripeInvoiceItemInfo {
  id: number
  invoice_id: number
  stripe_id: string
  plan_id: number | null
  description: string | null
  quantity: number
  base_cost: number
  discount: number
  tax: number
  amount: number
  amount_final: number
  subscription_item_stripe_id: string | null
}

export interface StripeInvoiceSchoolInfo {
  id: number
  contact_name: string
  contact_email: string
  contact_phone: string
  school_name: string
  school_address1: string
  school_address2: string
  school_town: string
  school_county: string
  school_postcode: string
  billing_name: string
  billing_address1: string
  billing_address2: string
  billing_town: string
  billing_county: string
  billing_postcode: string
  school_country: string
  school_country_code: CountryCode
  vat_number: string | null
}

export type SubscriptionType = 'free' | 'stripe' | 'invoice' | 'district'

export const StripeInvoiceStatus = ['open', 'promised', 'paid', 'uncollectable', 'void'] as const
export type StripeInvoiceStatus = typeof StripeInvoiceStatus[number]

export type StripeInvoicePaymentMethod = 'card' | 'cheque' | 'bacs' | 'other'

export const StripeInvoiceAccountRegion = ['GB', 'US'] as const
export type StripeInvoiceAccountRegion = ArrayElement<typeof StripeInvoiceAccountRegion>

/** Reason why the invoice was created, as defined in https://stripe.com/docs/api/invoices/object#invoice_object-billing_reason */
export const StripeInvoiceBillingReason = ['subscription_cycle', 'subscription_create', 'subscription_update', 'subscription', 'manual', 'upcoming', 'subscription_threshold'] as const
export type StripeInvoiceBillingReason = ArrayElement<typeof StripeInvoiceBillingReason>

export const InvoiceBillingReasonFilter = ['subscription_cycle', 'subscription_create'] as const
export type InvoiceBillingReasonFilter = typeof InvoiceBillingReasonFilter[number]

interface SubscriptionUpgradeCostRequestBase {
  base_plan_id: number
  add_on_plan_ids: number[]
  quantity: number
  type: 'normal' | 'backdated' | 'future'
}

interface CheckNewSubCostRequest extends SubscriptionUpgradeCostRequestBase {
  type: 'normal'
}

interface CheckFutureSubCostRequest extends SubscriptionUpgradeCostRequestBase {
  type: 'future'
  date: number
}

interface CheckBackdatedSubCostRequest extends SubscriptionUpgradeCostRequestBase {
  type: 'backdated'
  charge_type: 'pay-full' | 'pay-remaining'
  date: number
}

export type SubscriptionUpgradeCostRequest = CheckNewSubCostRequest | CheckFutureSubCostRequest | CheckBackdatedSubCostRequest
export interface SubscriptionUpgradeCostResponse {
  // The cost of the change in subscription - can be positive or negative.
  cost: number,
  // tax due on the change in subscription.
  cost_tax: number,
  // The amount the user must pay now - may be zero in some cases (excl tax).
  due_now: number,
  // tax due on the amount due now
  due_now_tax: number,
  // The amount of existing credit that will be applied.
  credit_applied: number,
  // The amount of existing credit that will be applied to cover tax.
  credit_applied_tax: number,
  // The amount of credit that will be left after upgrade.
  credit_remaining: number,
  // The discount received from existing subscription
  prorated_discount: number,
  // The date (as timestamp) from which pro-ration is applied. It is important to pass this value through when performing actual upgrade to get same cost.
  proration_date: number | null,
  // The amount the user will pay on renewal (Excl Tax).
  renewal_cost: number,
  // The amount of tax due on renewal.
  renewal_tax: number,
  // The date (as timestamp) on which renewal will take place.
  renewal_date: number | null
}

export interface CreateRemittanceRequest {
  invoice_id: string
  new_file: FileUpload
  date_received: Date
}

export interface UpdateRemittanceRequest {
  invoice_id: string
  new_file?: FileUpload
  date_received: Date
}

export interface GetSchoolSubscriptionsParams {
  q?: string
  subq?: string,
  invq?: string,
  groups?: SubscriptionPlanGroup[],
  currencies?: SubscriptionPlanCurrency[],
  regions?: SubscriptionPlanAccountRegion[],
  pricings?: SubscriptionPlanPriceType[],
  periods?: SubscriptionPlanPeriod[],
  products?: SubscriptionProductType[],
  types?: SubscriptionPlanAccountType[]
  plan?: number
  overdue?: boolean
  invoicestatus?: StripeInvoiceStatus
  showusd?: boolean,
  levels?: SubscriptionLevelType[]
}

export interface ScheduledUpdateInfo {
  schedule: any // really a Stripe.SubscriptionSchedule
  plans: SubscriptionPlanInfo[]
}

export interface RescheduleUpdateRequest {
  base_plan_id: number
  add_on_ids: number[]
  qty: number
}

export interface RescheduleSubscriptionResponse {
  new_schedule: any // Stripe.SubscriptionSchedule - prefer not to share a Stripe definition in common
  new_scheduled_change: ScheduledSubscriptionChange
}

export const NonCompliantSubType = ['school', 'personal'] as const
export type NonCompliantSubType = typeof NonCompliantSubType[number]

export interface NonCompliantSubsFilters {
  sub_type?: NonCompliantSubType[]
  org_type?: SchoolOrgType[]
  downloads?: { value: number, operator: '=' | '<' | '>' | '<=' | '>=' }
  license_count?: { value: number, operator: '=' | '<' | '>' | '<=' | '>=' }
  losing_access?: { type: 'with-downloads' | 'without-downloads', value: number, operator: '=' | '<' | '>' | '<=' | '>=' }
}

export interface InvoiceFilter {
  uk: boolean
  us: boolean
  edshed: boolean
  litshed: boolean
  id?: number,
  currencies?: Currency[],
  countries?: CountryCode[],
  startDate?: Date,
  endDate?: Date
  invoiceType?: InvoiceBillingReasonFilter
}

export interface NonCompliantSubData {
  id: number
  school_id: number
  download_count: number
  pupil_count: number
  teacher_count: number
  group_count: number
  losing_access: number
  losing_access_with_downloads: number
  pupil_seats: number
  latest_download_date: Date | null
  plans: SubscriptionPlanInfo[]
}
