import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
import state from './state'
import { State } from './types'

Vue.use(Vuex)

const getters = {

}

export type Getters = typeof getters

export default new Vuex.Store<State>({
  state,
  actions,
  mutations,
  getters
})
