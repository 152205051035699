import { ServerEnvInfo } from '@/edshed-common/servers'
import { ServerEnv, TargetEnv } from '@/edshed-common/servers/types'

const serverEnv = process.env.SERVER_ENV as ServerEnv
const targetEnv = process.env.TARGET_ENV as TargetEnv

const serverInfo: ServerEnvInfo = process.env.SERVER_INFO as any
const targetInfo: ServerEnvInfo = process.env.TARGET_INFO as any

console.log('Using server:', serverInfo.api)

export default {
  serverEnv,
  targetEnv,
  serverInfo,
  targetInfo,
  serverURI: serverInfo.api,
  authURI: serverInfo.auth,
  fixedLayout: false,
  hideLogoOnMobile: false
}
