import { SubscriptionPlanCurrency } from '../../subscriptionPackages'
import { DictionaryLanguage, VoiceLocale } from '../../i18n'
import { RosterOption } from './school'
import { ArrayElement } from '.'

export const CountryCode = ['GB-ENG', 'GB-NIR', 'GB-SCT', 'GB-WLS', 'GB-CHA', 'AF', 'AX', 'AL', 'DZ', 'AS', 'AD', 'AO', 'AI', 'AQ', 'AG',
  'AR', 'AM', 'AW', 'AU', 'AT', 'AZ', 'BS', 'BH', 'BD', 'BB', 'BY', 'BE', 'BZ', 'BJ', 'BM', 'BT', 'BO', 'BQ', 'BA', 'BW', 'BV', 'BR', 'IO',
  'BN', 'BG', 'BF', 'BI', 'KH', 'CM', 'CA', 'CV', 'KY', 'CF', 'TD', 'CL', 'CN', 'CX', 'CC', 'CO', 'KM', 'CG', 'CD', 'CK', 'CR', 'CI', 'HR',
  'CU', 'CW', 'CY', 'CZ', 'DK', 'DJ', 'DM', 'DO', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE', 'ET', 'FK', 'FO', 'FJ', 'FI', 'FR', 'GF', 'PF', 'TF',
  'GA', 'GM', 'GE', 'DE', 'GH', 'GI', 'EL', 'GL', 'GD', 'GP', 'GU', 'GT', 'GN', 'GW', 'GY', 'HT', 'HM', 'VA', 'HN', 'HK', 'HU', 'IS', 'IN',
  'ID', 'IR', 'IQ', 'IE', 'IM', 'IL', 'IT', 'JM', 'JP', 'JO', 'KZ', 'KE', 'KI', 'KR', 'KP', 'KW', 'KG', 'LA', 'LV', 'LB', 'LS', 'LR', 'LY',
  'LI', 'LT', 'LU', 'MO', 'MK', 'MG', 'MW', 'MY', 'MV', 'ML', 'MT', 'MH', 'MQ', 'MR', 'MU', 'YT', 'MX', 'FM', 'MD', 'MC', 'MN', 'ME', 'MS',
  'MA', 'MZ', 'MM', 'NA', 'NR', 'NP', 'NL', 'NC', 'NZ', 'NI', 'NE', 'NG', 'NU', 'NF', 'MP', 'NO', 'OM', 'PK', 'PW', 'PS', 'PA', 'PG', 'PY',
  'PE', 'PH', 'PN', 'PL', 'PT', 'PR', 'QA', 'RE', 'RO', 'RU', 'RW', 'BL', 'SH', 'KN', 'LC', 'MF', 'PM', 'VC', 'WS', 'SM', 'ST', 'SA', 'SN',
  'RS', 'SC', 'SL', 'SG', 'SX', 'SK', 'SI', 'SB', 'SO', 'ZA', 'GS', 'SS', 'ES', 'LK', 'SD', 'SR', 'SJ', 'SZ', 'SE', 'CH', 'SY', 'TW', 'TJ',
  'TZ', 'TH', 'TL', 'TG', 'TK', 'TO', 'TT', 'TN', 'TR', 'TM', 'TC', 'TV', 'UG', 'UA', 'AE', 'US', 'UM', 'UY', 'UZ', 'VU', 'VE', 'VN', 'VG',
  'VI', 'WF', 'EH', 'YE', 'ZM', 'ZW'] as const

export type CountryCode = ArrayElement<typeof CountryCode>

export const ZoneCode = ['US-AL', 'US-AK', 'US-AS', 'US-AZ', 'US-AR', 'US-CA', 'US-CO', 'US-CT', 'US-DE', 'US-DC', 'US-FL', 'US-GA', 'US-GU',
  'US-HI', 'US-ID', 'US-IL', 'US-IN', 'US-IA', 'US-KS', 'US-KY', 'US-LA', 'US-ME', 'US-MD', 'US-MA', 'US-MI', 'US-MN', 'US-MS', 'US-MO', 'US-MT',
  'US-NE', 'US-NV', 'US-NH', 'US-NJ', 'US-NM', 'US-NY', 'US-NC', 'US-ND', 'US-MP', 'US-OH', 'US-OK', 'US-OR', 'US-PA', 'US-PR', 'US-RI', 'US-SC',
  'US-SD', 'US-TN', 'US-TX', 'US-UM', 'US-UT', 'US-VT', 'US-VI', 'US-VA', 'US-WA', 'US-WV', 'US-WI', 'US-WY'] as const

export type ZoneCode = ArrayElement<typeof ZoneCode>

export const Iso3166CountryCode = [
  'AF', 'AX', 'AL', 'DZ', 'AS', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AR', 'AM', 'AW', 'AU', 'AT', 'AZ', 'BS', 'BH', 'BD', 'BB', 'BY', 'BE', 'BZ', 'BJ', 'BM', 'BT',
  'BO', 'BQ', 'BA', 'BW', 'BV', 'BR', 'IO', 'BN', 'BG', 'BF', 'BI', 'KH', 'CM', 'CA', 'CV', 'KY', 'CF', 'TD', 'CL', 'CN', 'CX', 'CC', 'CO', 'KM', 'CG', 'CD',
  'CK', 'CR', 'CI', 'HR', 'CU', 'CW', 'CY', 'CZ', 'DK', 'DJ', 'DM', 'DO', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE', 'ET', 'FK', 'FO', 'FJ', 'FI', 'FR', 'GF', 'PF',
  'TF', 'GA', 'GM', 'GE', 'DE', 'GH', 'GI', 'GR', 'GL', 'GD', 'GP', 'GU', 'GT', 'GG', 'GN', 'GW', 'GY', 'HT', 'HM', 'VA', 'HN', 'HK', 'HU', 'IS', 'IN', 'ID',
  'IR', 'IQ', 'IE', 'IM', 'IL', 'IT', 'JM', 'JP', 'JE', 'JO', 'KZ', 'KE', 'KI', 'KR', 'KP', 'KW', 'KG', 'LA', 'LV', 'LB', 'LS', 'LR', 'LY', 'LI', 'LT', 'LU',
  'MO', 'MK', 'MG', 'MW', 'MY', 'MV', 'ML', 'MT', 'MH', 'MQ', 'MR', 'MU', 'YT', 'MX', 'FM', 'MD', 'MC', 'MN', 'ME', 'MS', 'MA', 'MZ', 'MM', 'NA', 'NR', 'NP',
  'NL', 'NC', 'NZ', 'NI', 'NE', 'NG', 'NU', 'NF', 'MP', 'NO', 'OM', 'PK', 'PW', 'PS', 'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'PR', 'QA', 'RE', 'RO',
  'RU', 'RW', 'BL', 'SH', 'KN', 'LC', 'MF', 'PM', 'VC', 'WS', 'SM', 'ST', 'SA', 'SN', 'RS', 'SC', 'SL', 'SG', 'SX', 'SK', 'SI', 'SB', 'SO', 'ZA', 'GS', 'SS',
  'ES', 'LK', 'SD', 'SR', 'SJ', 'SZ', 'SE', 'CH', 'SY', 'TW', 'TJ', 'TZ', 'TH', 'TL', 'TG', 'TK', 'TO', 'TT', 'TN', 'TR', 'TM', 'TC', 'TV', 'UG', 'UA', 'AE',
  'GB', 'US', 'UM', 'UY', 'UZ', 'VU', 'VE', 'VN', 'VG', 'VI', 'WF', 'EH', 'YE', 'ZM', 'ZW'
] as const

export type Iso3166CountryCode = typeof Iso3166CountryCode[number]

export function isZoneCode (str: string): str is ZoneCode {
  return ZoneCode.includes(str as ZoneCode)
}

export function iso3166CountryToEdShed (code: Iso3166CountryCode): CountryCode {
  switch (code) {
    case 'GB':
      return 'GB-ENG' // this case is ambiguous, using England as it's bigger!
    case 'GG':
      return 'GB-CHA'
    case 'JE':
      return 'GB-CHA'
    case 'GR':
      return 'EL' // Greece uses EL for tax reasons.
    default:
      return code
  }
}

export function edShedCountryToIso3166 (code: CountryCode): Iso3166CountryCode {
  switch (code) {
    case 'GB-CHA':
      return 'JE' // this case is ambiguous, using Jersey as it's bigger!
    case 'GB-ENG':
    case 'GB-NIR':
    case 'GB-SCT':
    case 'GB-WLS':
      return 'GB'
    case 'EL':
      return 'GR' // Greece uses EL for tax reasons.
    default:
      return code
  }
}

export interface CountryInfo {
  id: number
  name: string
  code: CountryCode
  tax_rate: number
  reverse_charge: boolean
  default_currency: SubscriptionPlanCurrency
  default_language: DictionaryLanguage
  default_voice: VoiceLocale
  accepted_currencies: SubscriptionPlanCurrency[]
  roster_options: RosterOption[]
}

export interface CountryZoneInfo {
  id: number
  country_id: number
  name: string
  code: ZoneCode
  tax_rate: number
  reverse_charge: boolean
}

export interface EditCountryRequestData {
  default_currency?: SubscriptionPlanCurrency
  accepted_currencies?: SubscriptionPlanCurrency[]
  tax_rate?: number
  reverse_charge?: Boolean
  roster_options?: RosterOption[]
  default_language?: DictionaryLanguage
  default_voice?: VoiceLocale
}
